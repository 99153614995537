<!--
 * @Author: lzh
 * @Date: 2022-07-05 10:15:29
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 14:47:57
 * @Description: file content
-->
<template>
  <div class="our-clients-box px-our-clients-box">
    <h1
      class="title"
      :class="`edit_${Xindex}_title`"
      @click="clickItem('title')"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      :class="`edit_${Xindex}_desc`"
      @click="clickItem('desc')"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    ></h2>
    <div class="my-swiper">
      <el-carousel
        :autoplay="configs.autoplay"
        :interval="configs.interval * 1000 || 3000"
        :loop="configs.loop"
        indicator-position="none"
        @change="onChange"
      >
        <el-carousel-item
          v-for="(item, index) in configs.items"
          :key="index"
        >
          <img
            class="image"
            :src="item.img"
            :alt="item.alt"
            :class="`edit_${Xindex}_item_${index}`"
            @click="clickItem('item', index)"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="indicator">
        <div
          v-for="(item,index) in configs.items"
          :key="index"
          class="item"
          :class="index == changeActive ? 'active' :''"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-our-clients',
  mixins: [langMixin],
  data () {
    return {
      isEdit: false,
      changeActive: 0
    };
  },
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          autoplay: true, // 是否自動輪播
          interval: '3', // 時間
          items: [],

        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
    index: {
      default () {
        return '';
      },
    },
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    onChange (a, b) {
      this.changeActive = a;
    }
  },
};
</script>

<style lang="less" scoped>
.our-clients-box {
  padding: 60px 360px 116px 360px;
  text-align: center;
  font-family: PingFang SC-Regular, PingFang SC;
  .title {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
  }
  .my-swiper {
    margin-top: 60px;
    // width: calc(100vw - 720px);
    height: 580px;
    position: relative;
    /deep/.el-carousel {
      height: 100%;
      box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.06),
        -4px -4px 15px 1px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
    }
    /deep/ .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    /deep/ .el-carousel__container {
      height: 100%;

      border-radius: 10px;
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    /deep/.el-carousel__indicator--horizontal {
      margin-top: 16px;
    }
    .indicator {
      display: flex;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      .item {
        width: 20px;
        height: 20px;
        background: #eeeeee;
        border-radius: 50%;
        margin-left: 30px;
        &:nth-of-type(1) {
          margin: 0;
        }
        &.active {
          background: #dddddd;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-our-clients-box {
    padding: 30px 12px 37px;
    .title {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    .my-swiper {
      height: calc((100vw - 24px) / 2.11);
    }
  }
}
</style>
